import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TagManager from 'react-gtm-module';

class Tagging extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            siteLoaded: false,
        };
    }

    componentDidMount() {
        // Load only
        let pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
        if (this.props.sitecoreContext.route.templateName === 'ProductRoute')
            pageCategory = 'product detail page';
        else if (this.props.sitecoreContext.route.templateName === 'ArticleRoute')
            pageCategory = 'content page';
        else if (this.props.sitecoreContext.route.templateName === 'ProductFinderRoute')
            pageCategory = 'product selector page';

        window.dataLayer = window.dataLayer || [];
        if (!this.state.siteLoaded) {
            window.dataLayer.push({
                brand: 'BTE',
                language: 'fr',
                country: 'FR',
                siteTypeLevel: 'main',
                pageCategory: pageCategory,
            });

            this.setState({ siteLoaded: true });
        }

        const tagManagerArgs = {
            gtmId: 'GTM-T7BDXH5',
        };

        TagManager.initialize(tagManagerArgs);
    }

    componentWillUnmount() {
        window.removeEventListener('load', () =>
            addObserversForProducts(this.props.sitecoreContext)
        );
    }

    // ON PAGE CHANGE
    updateDataLayerPageview() {
        addObserversForProducts(this.props.sitecoreContext);

        let pageCategory = this.props.sitecoreContext.route.fields.pageCategoryGTM.value;
        if (this.props.sitecoreContext.route.templateName === 'ProductRoute')
            pageCategory = 'product detail page';
        else if (this.props.sitecoreContext.route.templateName === 'ArticleRoute')
            pageCategory = 'content page';
        else if (this.props.sitecoreContext.route.templateName === 'ProductFinderRoute')
            pageCategory = 'product selector page';

        window.dataLayer = window.dataLayer || [];
        var json = {
            event: 'updatevirtualpath',
            brand: 'BTE',
            language: 'fr',
            country: 'fr',
            siteTypeLevel: 'main',
            pageCategory: pageCategory,
            virtualPageUrl: window.location.pathname,
            virtualPageTitle: this.props.sitecoreContext.route.displayName,
        };
        window.dataLayer.push(json);
    }

    componentDidUpdate(prevProps, prevState) {
        Object.entries(this.props).forEach(([key, val]) => {
            if (prevProps[key] !== val) {
                if (key == 'pageTitle') {
                    this.updateDataLayerPageview();
                }
            }
        });
    }

    render() {
        return <div></div>;
    }
}

export default withSitecoreContext()(Tagging);

export function observeProductFinder(context) {
    addObserversForProducts(context);
}

// NAVIGATION MENUS
export function handleNavigationClick(area, label) {
  window.dataLayer.push({
    event: 'uaevent',
    event_name: 'menu_click',
    ecommerce: 'undefined',
    eventCategory: 'main menu navigation',
    eventAction: 'select::' + area,
    eventLabel: label,
    click_area: area,
    breadcrumb: label,
  });
}

// IMAGEBLOCK BUTTON CLICK
export function handleLinkClick(ctaName, url, pageCategory) {
    window.dataLayer.push({
        event: 'uaevent',
        event_name: 'body_button_click',
        ecommerce: 'undefined',
        eventCategory: pageCategory,
        eventAction: 'select::homepage block',
        eventLabel: ctaName + '::' + url,
        module_name: 'homepage block',
        cta_name: ctaName,
        link_url: url,
    });
}


function addObserversForProducts(context){
  // PRODUCT IMPRESSIONS
  setTimeout(function(){
    document.querySelectorAll(".productTag").forEach(function(elem){
      
      let observer = new IntersectionObserver(function(entries){
        if(entries[0].isIntersecting === true){
          
        const name = entries[0].target.getAttribute("data-gtm-name")
        const eanCode = entries[0].target.getAttribute("data-gtm-ean")
        const productCategory = entries[0].target.getAttribute("data-gtm-category")
        const productRating = parseFloat(entries[0].target.getAttribute("data-gtm-rating"))
        const skindr = (JSON.parse(localStorage.getItem('prescriptedList'))?.includes(eanCode)) ? 'skindr' : 'none'
        
        let pageCategory = context.route.fields.pageCategoryGTM.value
        if(context.route.templateName === 'ProductRoute') pageCategory = "product detail page"
        else if(context.route.templateName === 'ArticleRoute') pageCategory = "content page"
        else if(context.route.templateName === 'ProductFinderRoute') pageCategory = "product selector page"

        window.dataLayer.push ({ 
          'event': 'nievent',
          'eventCategory':'Ecommerce',
          'eventAction': 'Product Impressions',
          'eventLabel': pageCategory,
          'event_name': 'view_item_list',
          'ecommerce': {
            'currencyCode': 'EUR',
            'impressions': [{
              'name': name,
              'id': eanCode,
              'category': productCategory,
              'dimension38': productRating,
              'dimension48': eanCode,
              'dimension91': skindr
            }]
          }
          

        })
        
        observer.unobserve(entries[0].target)
      }
    }, {threshold: [0.10]})

    observer.observe(elem)
  })
}, 2500)
}